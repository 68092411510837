import { ErrorMessage } from "@stedi/dls";
import { AuthError } from "../../../api/errors";
import { UseStediRouter } from "../../../routes";
import { RouteTextLink } from "../../../shared/components/RouteTextLink";
import { services, useServices } from "../../../shared/context/ServicesProvider";

interface SignUpErrorProps {
  error: AuthError;
}

function isExistingUserError(error: AuthError) {
  return (
    error.code === "UsernameExistsException" || error.message === "PreSignUp failed with error User already exists."
  );
}

function SignUpError({ error }: SignUpErrorProps) {
  const errorMessage = error.message;

  const isExistingUser = isExistingUserError(error);
  if (isExistingUser) {
    return (
      <ErrorMessage data-testid="auth-error">
        <UserExistsError />
      </ErrorMessage>
    );
  }

  return <ErrorMessage data-testid="auth-error">{errorMessage}</ErrorMessage>;
}

function UserExistsError() {
  const getService = useServices();
  const useStediRouter = getService<UseStediRouter>(services.useStediRouter);
  const router = useStediRouter();
  return (
    <>
      A user account with the given email has already been created. Please{" "}
      <RouteTextLink to={router.signIn()}>sign in</RouteTextLink>
    </>
  );
}

export { SignUpError };
