import { useLocation } from "react-router-dom";
import { UseStediNavigate } from "../../../routes";
import { services, useServices } from "../../../shared/context/ServicesProvider";
import * as url from "../../../shared/utils/url";
import ForgotPassword from "./ForgotPassword";

export default function ForgotPasswordPage(): JSX.Element {
  const getService = useServices();
  const useStediNavigate = getService<UseStediNavigate>(services.useStediNavigate);

  const navigate = useStediNavigate();
  const location = useLocation();

  const { email: initialEmail } = url.parseQueryString(location.search);

  return (
    <ForgotPassword
      initialEmail={initialEmail || ""}
      onCodeSentToEmail={(email: string) => navigate.resetPassword({ email })}
    />
  );
}
