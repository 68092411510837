import * as React from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  color,
  EmailField,
  ErrorMessage,
  Flex,
  Form,
  PasswordField,
  size,
  TextField,
  TextLink,
} from "@stedi/dls";
import { AuthError, getMessageFromError } from "../../../api/errors";
import { isMemberInvite, isPartnerInvite, UseStediRouter } from "../../../routes";
import { RouteTextLink } from "../../../shared/components/RouteTextLink";
import { services, useServices } from "../../../shared/context/ServicesProvider";
import { getRedirectPath } from "../../../shell/util/redirects";
import AuthLayout from "../AuthLayout";
import { SignUpError } from "./SignUpError";

export interface SignUpProps {
  initialEmail?: string;
  signUp: (fields: { email: string; password: string; firstName: string; lastName: string }) => Promise<void>;
  authError: AuthError | null;
}

interface SignUpFormFields {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  "": string;
}

const getTitle = (path: string | null = "") => {
  if (isMemberInvite(path)) return "Sign up to join an account";
  if (isPartnerInvite(path)) return "Sign up to accept your trading partner invitation";
  return "Sign up for free";
};

export const LegalText = ({ action }: { action: string }) => (
  <Box as="p" color={color.gray10} fontSize="s2" style={{ lineHeight: 1.5 }}>
    By {action}, you agree to the{" "}
    <TextLink color={color.gray10} href="https://www.stedi.com/docs/legal/customer-agreement">
      Customer Agreement
    </TextLink>
    ,{" "}
    <TextLink color={color.gray10} href="https://www.stedi.com/docs/legal/privacy-notice">
      Privacy Notice
    </TextLink>
    , and{" "}
    <TextLink color={color.gray10} href="https://www.stedi.com/docs/legal/service-terms">
      Service Terms
    </TextLink>
  </Box>
);

const SignUp: React.FC<SignUpProps> = ({ authError, initialEmail = "", signUp }) => {
  const getService = useServices();
  const useStediRouter = getService<UseStediRouter>(services.useStediRouter);

  const router = useStediRouter();
  const path = getRedirectPath();
  const methods = useForm<SignUpFormFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      email: initialEmail || "",
    },
  });

  const { formState, handleSubmit, setError, watch } = methods;
  const { errors, isSubmitting } = formState;
  const onSubmit = handleSubmit(async ({ email, firstName, lastName, password }) => {
    try {
      await signUp({ email, password, firstName, lastName });
    } catch (e) {
      setError("", { type: "validate", message: getMessageFromError(e) });
    }
  });

  const errorMessage = authError ? (
    <SignUpError error={authError} />
  ) : (
    errors[""] && <ErrorMessage data-testid="form-error">{errors[""].message}</ErrorMessage>
  );

  return (
    <AuthLayout title={getTitle(path)}>
      <Box as="p" mb="s4">
        Already have an account?{" "}
        <RouteTextLink params={{ email: watch().email || "" }} to={router.root()}>
          Sign in
        </RouteTextLink>
      </Box>
      <Form methods={methods} onSubmit={onSubmit}>
        <fieldset>
          <Flex flexGap="s4">
            <TextField
              autoComplete="given-name"
              autoFocus={true}
              errorMessage="Please enter your first name"
              label="First name"
              name="firstName"
              placeholder="Enter your first name"
              required={true}
              style={{ fontSize: size.s4 }}
            />
            <TextField
              autoComplete="family-name"
              errorMessage="Please enter your last name"
              label="Last name"
              name="lastName"
              placeholder="Enter your last name"
              required={true}
              style={{ fontSize: size.s4 }}
            />
          </Flex>
          <EmailField
            autoComplete="email"
            defaultValue={initialEmail}
            label="Email"
            name="email"
            placeholder="Enter your work email"
            required={true}
            style={{ fontSize: size.s4 }}
          />
          <PasswordField
            autoComplete="new-password"
            errorMessage="Please choose a valid password"
            label="Password"
            name="password"
            required={true}
            style={{ fontSize: size.s4 }}
          />
          {errorMessage}
        </fieldset>
        <Flex alignItems="flex-start" flexDirection="column" gap="s4" justifyContent="space-between">
          <Button isLoading={isSubmitting} scale="large" type="submit">
            Create account
          </Button>
          <LegalText action="creating an account" />
        </Flex>
      </Form>
    </AuthLayout>
  );
};

export default SignUp;
