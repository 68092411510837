import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, EmailField, ErrorMessage, Form, HideVisually, PasswordField, space, TextField } from "@stedi/dls";
import { getMessageFromError } from "../../../api/errors";
import AuthLayout from "../AuthLayout";

export interface ResetPasswordProps {
  emailProvided?: string;
  resetCode?: string;
  forgotPasswordSubmit: (vars: { email: string; password: string; code: string }) => Promise<string>;
}

interface ResetPasswordFormFields {
  password: string;
  code: string;
  email: string;
  "": string;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  emailProvided,
  forgotPasswordSubmit,
  resetCode,
}: ResetPasswordProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const methods = useForm<ResetPasswordFormFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
  });
  const { formState, handleSubmit, setError } = methods;
  const { errors } = formState;
  const onSubmit = handleSubmit(async ({ code, email, password }) => {
    try {
      setIsLoading(true);
      await forgotPasswordSubmit({ email, password, code });
    } catch (e) {
      setError("", { type: "validate", message: getMessageFromError(e) });
      setIsLoading(false);
    }
  });

  return (
    <AuthLayout hideLogo={true} title="Confirm your email address to reset your password">
      <p style={{ marginBottom: space.s4 }}>
        If you have a verified account, a confirmation code will be sent to {emailProvided ?? "your account's e-mail"}.
        Please enter it below and choose a new password.
      </p>
      <Form methods={methods} onSubmit={onSubmit}>
        <fieldset style={{ marginTop: space.s4 }}>
          <HideVisually hide={!!emailProvided}>
            <EmailField autoComplete="email" defaultValue={emailProvided} label="Email" name="email" />
          </HideVisually>
          <TextField
            autoComplete="one-time-code"
            autoFocus={true}
            defaultValue={resetCode}
            errorMessage={`Please enter the confirmation code emailed to ${emailProvided ?? "your account's e-mail"}`}
            label="Confirmation code"
            name="code"
            placeholder="Enter confirmation code"
            required={true}
          />
          <PasswordField
            autoComplete="new-password"
            errorMessage="Please choose a valid password"
            label="New password"
            name="password"
            placeholder="Choose a new password"
            required={true}
          />
        </fieldset>
        {errors[""] && <ErrorMessage data-testid="form-error">{errors[""].message}</ErrorMessage>}
        <footer>
          <Button disabled={isLoading || formState.isSubmitting} isLoading={formState.isSubmitting} type="submit">
            Reset password
          </Button>
        </footer>
      </Form>
    </AuthLayout>
  );
};

export default ResetPassword;
