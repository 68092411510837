import * as React from "react";
import styled from "@emotion/styled";
import { color, ErrorMessage, flex, space, Spinner, TextLink } from "@stedi/dls";
import { getMessageFromError } from "../../../api/errors";
import { useResendCode } from "../../../shared/hooks/authentication";

const SResendContainer = styled.div({
  ...flex("flex-start", "space-between"),
  a: {
    ...flex(),
    marginTop: space.s2,
    marginBottom: space.s4,
    whiteSpace: "nowrap",
  },
  span: {
    marginTop: space.s2,
    color: color.desert,
  },
});

export interface ResendConfirmationCodeProps {
  email?: string;
}

export const ResendConfirmationCode: React.FC<ResendConfirmationCodeProps> = ({ email }) => {
  const { error, isLoading, isSuccess, mutate: resendCode } = useResendCode();

  return (
    <>
      <SResendContainer>
        <TextLink
          onClick={() => {
            resendCode({ email: email ?? "" });
          }}
        >
          {!isLoading ? "Need a new code?" : <Spinner label="Emailing code" reverseLabel={true} />}
        </TextLink>
        {isSuccess && <span data-testid="form-success">New code sent to {email}</span>}
      </SResendContainer>
      {error && <ErrorMessage>{getMessageFromError(error)}</ErrorMessage>}{" "}
    </>
  );
};
