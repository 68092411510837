import { useLocation } from "react-router-dom";
import { UseStediNavigate } from "../../../routes";
import { services, useServices } from "../../../shared/context/ServicesProvider";
import { SignUpVariables, useSignUp } from "../../../shared/hooks/authentication";
import { parseQueryString } from "../../../shared/utils/url";
import { setRedirectPath } from "../../../shell/util/redirects";
import SignUp from "./SignUp";

export default function SignUpPage(): JSX.Element {
  const getService = useServices();
  const useStediNavigate = getService<UseStediNavigate>(services.useStediNavigate);

  const location = useLocation();
  const { error, mutateAsync: signUp } = useSignUp();
  const navigate = useStediNavigate();

  const { email, redirectTo } = parseQueryString(location.search);

  if (redirectTo) {
    setRedirectPath(redirectTo as string);
  }

  async function submit(input: SignUpVariables): Promise<void> {
    await signUp(input, {
      onSuccess: (signUpProps) => navigate.confirmEmail({ email: signUpProps.email, password: signUpProps.password }),
    });
  }

  return <SignUp authError={error} initialEmail={email || ""} signUp={submit} />;
}
