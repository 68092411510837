import * as React from "react";
import { useCallback, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import ConfirmSignUpPage from "../../components/auth/ConfirmSignUp/ConfirmSignUpPage";
import ForgotPasswordPage from "../../components/auth/ForgotPassword";
import ResetPage from "../../components/auth/ForgotPassword/ResetPage";
import SignInPage from "../../components/auth/SignIn/SignInPage";
import SignUpPage from "../../components/auth/SignUp/SignUpPage";
import { isMemberInvite, isPublicRoute, paths } from "../../routes";
import { addMessageListener, Message, removeMessageListener } from "../../shared/utils/broadcast";
import { setRedirectWithoutAccount } from "../util/redirects";

function RedirectToSignInOrSignUp() {
  const location = useLocation();

  if (location) {
    const path = location.pathname;
    const search = location.search;
    if (!isPublicRoute(path)) {
      setRedirectWithoutAccount(`${path}${search}`);
    }

    if (isMemberInvite(path)) return <Redirect to={paths.signUp} />;
  }

  return <Redirect to="/" />;
}

const UnauthenticatedApp: React.FC = () => {
  const location = useLocation();
  const path = location.pathname;
  const search = location.search;
  const signOut = useCallback(
    async (msg: Message) => {
      if (msg.messageType === "signed-out") {
        setRedirectWithoutAccount(`${path}${search}`);
      }
    },
    [path, search],
  );

  useEffect(() => {
    addMessageListener(signOut);
    return () => removeMessageListener(signOut);
  });

  return (
    <>
      <Switch>
        <Route exact={true} path={paths.root}>
          <SignInPage />
        </Route>
        <Route path={paths.signUp}>
          <SignUpPage />
        </Route>
        <Route path={paths.confirmEmail}>
          <ConfirmSignUpPage />
        </Route>
        <Route path={paths.forgotPassword}>
          <ForgotPasswordPage />
        </Route>
        <Route path={paths.resetPassword}>
          <ResetPage />
        </Route>
        <Route path="*">
          <RedirectToSignInOrSignUp />
        </Route>
      </Switch>
    </>
  );
};

export default UnauthenticatedApp;
