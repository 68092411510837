import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, EmailField, ErrorMessage, Form, HideVisually, PasswordField, space, TextField } from "@stedi/dls";
import { getMessageFromError } from "../../../api/errors";
import { isMemberInvite, UseStediNavigate } from "../../../routes";
import { services, useServices } from "../../../shared/context/ServicesProvider";
import { useConfirm, useSignIn } from "../../../shared/hooks/authentication";
import { addMessageListener, Message, removeMessageListener } from "../../../shared/utils/broadcast";
import { getRedirectPath, redirectIfExternalStediUrl } from "../../../shell/util/redirects";
import AuthLayout from "../AuthLayout";
import { ResendConfirmationCode } from "./ResendConfirmationCode";

export interface ConfirmSignUpNewProps {
  initialEmail?: string;
  initialPassword?: string;
  confirmCode?: string;
}

interface ConfirmSignUpNewFields {
  email: string;
  code: string;
  password: string;
  "": string;
}

const navigateToNextUrl = (navigate: ReturnType<UseStediNavigate>) => {
  const path = getRedirectPath();

  if (path && isMemberInvite(path)) {
    navigate.redirect({ args: { path } });
    return;
  }

  if (redirectIfExternalStediUrl()) {
    return;
  }

  navigate.createAccount();
};

const ConfirmSignUp = ({ confirmCode, initialEmail, initialPassword }: ConfirmSignUpNewProps) => {
  const getService = useServices();
  const useStediNavigate = getService<UseStediNavigate>(services.useStediNavigate);
  const navigate = useStediNavigate();

  const methods = useForm<ConfirmSignUpNewFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
  });
  const { formState, handleSubmit } = methods;

  const { error, mutate: confirm } = useConfirm({
    onSuccess: async () => {
      navigateToNextUrl(navigate);
    },
  });

  const { mutate: signIn } = useSignIn();

  const onConfirmedEmailMessage = React.useCallback(
    async (msg: Message) => {
      if (msg.messageType !== "confirmed-email") return;

      /**
       * We have to sign-in when receiving a broadcast message,
       * otherwise the post-confirmation redirect doesn't work properly.
       *
       * Reference issue: https://github.com/Stedi/transformers/issues/4186
       */
      const { email, password } = methods.getValues();
      signIn(
        { email, password },
        {
          onSuccess: () => navigateToNextUrl(navigate),
          onError: (e, state) => navigate.signIn(state),
        },
      );
    },
    [methods, navigate, signIn],
  );

  React.useEffect(() => {
    addMessageListener(onConfirmedEmailMessage);
    return () => removeMessageListener(onConfirmedEmailMessage);
  });

  const onSubmit = handleSubmit(async ({ code, email, password }) => {
    confirm({ code, email, password });
  });

  const isPasswordAlreadyEntered = Boolean(initialPassword);

  return (
    <AuthLayout hideLogo={true} title="Confirm your email">
      <p style={{ marginBottom: space.s4 }}>
        A confirmation code was sent to {initialEmail ? initialEmail.trim() : "your email"}. Please enter it below to
        confirm your email.
      </p>
      <Form methods={methods} onSubmit={onSubmit}>
        <fieldset>
          <EmailField
            autoComplete="email"
            defaultValue={initialEmail}
            label="Email"
            name="email"
            placeholder="Enter your work email"
            required={true}
          />
          <TextField
            autoComplete="one-time-code"
            autoFocus={isPasswordAlreadyEntered}
            defaultValue={confirmCode}
            errorMessage="Please enter your confirmation code"
            label="Confirmation code"
            name="code"
            placeholder="Please enter your confirmation code"
            required={true}
          />
          <HideVisually hide={isPasswordAlreadyEntered}>
            <PasswordField
              autoFocus={!isPasswordAlreadyEntered}
              defaultValue={initialPassword}
              disableValidation={true}
              name="password"
              placeholder="Enter your password"
              required={true}
            />
          </HideVisually>

          <ResendConfirmationCode email={initialEmail} />
          {error && <ErrorMessage data-testid="form-error">{getMessageFromError(error)}</ErrorMessage>}
        </fieldset>
        <Button isLoading={formState.isSubmitting} type="submit">
          Confirm
        </Button>
      </Form>
    </AuthLayout>
  );
};

export default ConfirmSignUp;
