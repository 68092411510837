import * as React from "react";
import { useForm } from "react-hook-form";
import { Box, Button, color, EmailField, ErrorMessage, Flex, Form, PasswordField, size } from "@stedi/dls";
import ArrowRightIcon from "remixicon-react/ArrowRightLineIcon";
import { getMessageFromError, UnconfirmedUserError } from "../../../api/errors";
import { isMemberInvite, isPartnerInvite, UseStediNavigate, UseStediRouter } from "../../../routes";
import { RouteTextLink } from "../../../shared/components/RouteTextLink";
import { services, useServices } from "../../../shared/context/ServicesProvider";
import { useSignIn } from "../../../shared/hooks/authentication";
import { getRedirectPath } from "../../../shell/util/redirects";
import AuthLayout from "../AuthLayout";
import { LegalText } from "../SignUp/SignUp";

interface SignInProps {
  initialEmail: string;
}

interface SignInFormFields {
  email: string;
  password: string;
}

const getTitle = (path: string | null = "") => {
  if (isMemberInvite(path)) return "Sign in to join an account";
  if (isPartnerInvite(path)) return "Sign in to accept your trading partner invitation";
  return "Sign in to Stedi";
};

const SignIn: React.FunctionComponent<SignInProps> = ({ initialEmail }) => {
  const getService = useServices();
  const useStediNavigate = getService<UseStediNavigate>(services.useStediNavigate);
  const useStediRouter = getService<UseStediRouter>(services.useStediRouter);

  const { error, isLoading, mutate: signIn } = useSignIn();
  const navigate = useStediNavigate();
  const router = useStediRouter();
  const path = getRedirectPath();

  const methods = useForm<SignInFormFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      email: initialEmail,
    },
  });

  const { handleSubmit, watch } = methods;

  const onSubmit = handleSubmit(async ({ email, password }) => {
    signIn(
      { email, password },
      {
        onError: async (e, state) => {
          if (e instanceof UnconfirmedUserError) {
            navigate.confirmEmail(state);
          }
        },
      },
    );
  });

  const { email } = watch();

  return (
    <AuthLayout title={getTitle(path)}>
      <Box as="p" mb="s4">
        Need an account?{" "}
        <RouteTextLink params={{ email: email || "" }} to={router.signUp()}>
          Sign up for free
        </RouteTextLink>
      </Box>
      <Form methods={methods} onSubmit={onSubmit}>
        <fieldset>
          <EmailField
            autoComplete="email"
            autoFocus={true}
            label="Email"
            name="email"
            placeholder="Enter your work email"
            required={true}
            style={{ fontSize: size.s4 }}
          />
          <PasswordField
            autoComplete="current-password"
            disableValidation={true}
            label="Password"
            name="password"
            placeholder="Enter your password"
            required={true}
            style={{
              fontSize: size.s4,
              paddingRight: 40,
            }}
          >
            <RouteTextLink params={{ email }} style={{ color: color.c30 }} to={router.forgotPassword()}>
              I forgot my password
            </RouteTextLink>
          </PasswordField>
          {error && <ErrorMessage data-testid="form-error">{getMessageFromError(error)}</ErrorMessage>}
        </fieldset>
        <Flex alignItems="flex-start" flexDirection="column" gap="s4" justifyContent="space-between">
          <Button
            data-testid="sign-in-button"
            disabled={isLoading}
            flex="none"
            fontSize="s4"
            isLoading={isLoading}
            scale="large"
            trailingIcon={<ArrowRightIcon />}
            type="submit"
          >
            Sign in
          </Button>
          <LegalText action="signing in" />
        </Flex>
      </Form>
    </AuthLayout>
  );
};

export default SignIn;
