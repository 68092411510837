import { useLocation } from "react-router-dom";
import { useForgotPasswordSubmit } from "../../../shared/hooks/authentication";
import { parseQueryString } from "../../../shared/utils/url";
import ResetPassword from "./ResetPassword";

export default function ForgotPasswordPage(): JSX.Element {
  const location = useLocation<{ email?: string } | undefined>();
  const { mutateAsync: forgotPasswordSubmit } = useForgotPasswordSubmit();

  const { code: queryCode, email: queryEmail } = parseQueryString(location.search);

  const email = location.state?.email || queryEmail || "";
  const code = queryCode || "";

  return <ResetPassword emailProvided={email} forgotPasswordSubmit={forgotPasswordSubmit} resetCode={code} />;
}
