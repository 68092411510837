import { useLocation } from "react-router-dom";
import { parseQueryString } from "../../../shared/utils/url";
import ConfirmSignUp from "./ConfirmSignUp";

const ConfirmSignUpPage = (): JSX.Element => {
  const location = useLocation<{ email?: string; password?: string } | undefined>();
  const { state } = location;

  const { code, email } = parseQueryString(location.search);

  return (
    <ConfirmSignUp confirmCode={code} initialEmail={state?.email || email || ""} initialPassword={state?.password} />
  );
};

export default ConfirmSignUpPage;
