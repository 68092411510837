const minimumLength = value => value.length >= 8;
const containsNumber = value => /\d/g.test(value);
const containsLowercaseLetter = value => value.toLocaleUpperCase() !== value;
const containsUppercaseLetter = value => value.toLocaleLowerCase() !== value; // Cognito supported special characters
// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html

const SpecialCharacters = ["^", "$", "*", ".", "[", "]", "{", "}", "(", ")", "?", "“", "!", "@", "#", "%", "&", "/", ",", ">", "<", "’", ":", ";", "|", "_", "~", "`"];
const containsSpecialCharacter = value => SpecialCharacters.some(c => value.includes(c));
const validatePassword = value => minimumLength(value) && containsNumber(value) && containsLowercaseLetter(value) && containsUppercaseLetter(value) && containsSpecialCharacter(value);

export { SpecialCharacters, containsLowercaseLetter, containsNumber, containsSpecialCharacter, containsUppercaseLetter, minimumLength, validatePassword };
