import { useLocation } from "react-router-dom";
import { parseQueryString } from "../../../shared/utils/url";
import { setRedirectPath } from "../../../shell/util/redirects";
import SignIn from "./SignIn";

const SignInPage = () => {
  const location = useLocation();

  const { email, redirectTo } = parseQueryString(location.search);
  if (redirectTo) {
    setRedirectPath(redirectTo as string);
  }

  return <SignIn initialEmail={email || ""} />;
};

export default SignInPage;
