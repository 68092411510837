import { __rest } from '../../node_modules/tslib/tslib.es6.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { space } from '../constants.js';
import { Flex } from './Flex.js';
import { ErrorIcon } from './icons/ErrorIcon.js';

const ErrorMessage = _a => {
  var {
    children,
    iconSize = 14,
    removeBottomMargin
  } = _a,
      restProps = __rest(_a, ["children", "iconSize", "removeBottomMargin"]);

  return jsxs(Flex, Object.assign({
    color: "red",
    display: "inline-flex",
    mb: removeBottomMargin ? "s0" : "s4"
  }, restProps, {
    children: [jsx(ErrorIcon, {
      fixedSize: iconSize,
      style: {
        marginRight: space.s2
      }
    }), jsx("span", {
      children: children
    })]
  }));
};

export { ErrorMessage };
