import { FieldError, useForm } from "react-hook-form";
import { Button, EmailField, ErrorMessage, Form, size, space, Spinner, TextLink } from "@stedi/dls";
import { getMessageFromError } from "../../../api/errors";
import { UseStediRouter } from "../../../routes";
import { RouteTextLink } from "../../../shared/components/RouteTextLink";
import { services, useServices } from "../../../shared/context/ServicesProvider";
import { useForgotPassword, useResendCode } from "../../../shared/hooks/authentication";
import AuthLayout from "../AuthLayout";

interface ForgotPasswordProps {
  initialEmail: string;
  onCodeSentToEmail: (email: string) => void;
}

interface ForgotPasswordFields {
  email: string;
  "": string;
}

export default function ForgotPassword({ initialEmail, onCodeSentToEmail }: ForgotPasswordProps): JSX.Element {
  const { mutateAsync: forgotPassword } = useForgotPassword();
  const getService = useServices();
  const useStediRouter = getService<UseStediRouter>(services.useStediRouter);
  const router = useStediRouter();

  const methods = useForm<ForgotPasswordFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { clearErrors, formState, getValues, handleSubmit, setError } = methods;
  const { errors } = formState;

  const onSubmit = handleSubmit(async () => {
    const { email } = getValues();
    try {
      await forgotPassword({ email });
      onCodeSentToEmail(email);
    } catch (e: any) {
      if (e.code === "UserNotFoundException") {
        onCodeSentToEmail(email);
      } else {
        setError("", { type: e.code, message: getMessageFromError(e) });
      }
    }
  });

  const { isLoading: resendCodeIsLoading, isSuccess: resendCodeSuccess, mutate: resendCode } = useResendCode();

  const params = { email: getValues().email };

  const renderError = (e: FieldError): JSX.Element => {
    switch (e.type) {
      case "UserNotConfirmedException":
        return (
          <p>
            {e.message}. To verify your email, you may{" "}
            <TextLink
              onClick={async () => {
                resendCode(params);
              }}
            >
              {!resendCodeIsLoading ? (
                "request confirmation code."
              ) : (
                <Spinner label="Emailing code" reverseLabel={true} />
              )}
            </TextLink>
          </p>
        );
      default:
        return <p>{e.message}</p>;
    }
  };

  return (
    <AuthLayout hideLogo={true} title="Reset your password">
      <Form methods={methods} onSubmit={onSubmit}>
        <fieldset style={{ marginTop: space.s4 }}>
          <EmailField
            autoComplete="email"
            autoFocus={true}
            defaultValue={initialEmail}
            label="Email"
            name="email"
            placeholder="Enter your work email"
            required={true}
            style={{ fontSize: size.s4 }}
          />
          {errors[""] && !resendCodeSuccess && (
            <ErrorMessage data-testid="form-error">{renderError(errors[""])}</ErrorMessage>
          )}
          {resendCodeSuccess && (
            <p data-testid="confirm-code-success" style={{ marginBottom: space.s4 }}>
              <RouteTextLink params={params} to={router.confirmEmail()}>
                Enter confirmation code
              </RouteTextLink>{" "}
              to verify your email.
            </p>
          )}
        </fieldset>
        <Button isLoading={formState.isSubmitting} onClick={() => clearErrors()} type="submit">
          Send password reset code
        </Button>
      </Form>
    </AuthLayout>
  );
}
